<template>
  <div class="contain" style="margin:0">
    <!-- 字典配置 -->
    <el-table
      :data="tableData"
      style="width: 100%; margin: 10px 0"
      class="table-no-search"
      row-key="id"
      border
      :default-expand-all="false"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      stripe
      height="calc(100vh - 265px)"
      :header-cell-style="{
        color: '#333',
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        fontWeight: 900,
        textAlign:'center',
        background: '#f8f8f9',
      }"
    >
      <el-table-column
        prop="name"
        label="字典名称"
        align="left"
      >
      <!-- <template slot-scope="{ row }">{{row}}
        </template> -->
      </el-table-column>
      <el-table-column prop="id" label="ID" align="left"></el-table-column>
      <el-table-column
        prop="sorting"
        label="字典排序"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        align="left"
      ></el-table-column>
      <el-table-column label="操作" align="left">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            v-if="row.parentId != 0 && row.parentId != 111 && $anthButtons.getButtonAuth('updataLexical') "
            @click="updataDIC(row)"
            size="small"
            >编辑</el-button
          >
          <el-button
            type="primary"
            v-if="row.rank == 1 && $anthButtons.getButtonAuth('addLexical')"
            @click="addDIC(row.id)"
            size="small"
            >新增子字典</el-button
          >
          <el-button
            type="danger"
            v-if="row.isDelete != 1 && row.parentId != 0 && row.parentId != 111 && $anthButtons.getButtonAuth('deleteLexical')"
            @click="del(row)"
            size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="updatasonStatus == 0 ? '新增字典信息' : '修改字典信息'"
      :visible.sync="sondialogVisible"
      width="35%"
      :close-on-click-modal="false"
      @close="clearnsonF('sonform')"
    >
      <el-form ref="sonform" :model="sonform" label-width="130px" :rules="rule">
        <el-form-item label="字典名称：" prop="name">
          <el-input v-model="sonform.name"></el-input>
        </el-form-item>
        <el-form-item label="字典排序：" prop="sorting">
          <el-input v-model="sonform.sorting" type="number"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sondialogVisible = false">取 消</el-button>
        <el-button
          v-if="updatasonStatus == 0"
          type="primary"
          @click="submitsonfrom('sonform')"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="updatasubmitsonfrom('sonform')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  dicpage,
  dicsave,
  dicupdata,
} from "../../RequestPort/Login/LoginRequest";
import { patrolGetDict ,patrolEditDict,batchDelDict,addDict } from "../../RequestPort/maintenance";
import {
  dicdelete,
} from "../../RequestPort/dict/cycle";
export default {
  name: "Dict",
  data() {
    return {
      pageParams: {
        name: "",
      },
      sondialogVisible: false,
      tableData: [{}],
      sonform: {
        platform: 4,
        name: "",
        sorting: 0,
        code: "",
        description: "",
      },
      updatasonStatus: 0,
      rule: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        sorting: [{ required: true, message: "请输入排序", trigger: "blur" }],
        code: [{ required: true, message: "请输入编码", trigger: "blur" }],
        englishName: [
          { required: true, message: "请输入英文名称", trigger: "blur" },
        ],
        value: [{ required: true, message: "请输入值", trigger: "blur" }],
      },
      options: [],
      value: "",
    };
  },
  mounted() {
    this.loadlist();
  },
  methods: {
    /**
     * 获取字典数据
     */
    loadlist(obj) {
      patrolGetDict({pid:'111,112,113,114'}).then(res => {
        if(res.code == 200) {
          this.tableData = res.data;
          this.tableData.forEach(el => {
            if(el.children && el.children.length > 0) {
              el.children.forEach(item => {
                item.rank = 1
              })
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    recet() {
      this.pageParams.name = "";
      this.loadlist(this.pageParams);
    },
    clearnsonF(sonform) {
      (this.sonform = {
        platform: 2,
        name: "",
        sorting: 0,
        code: "",
      }),
        this.$refs[sonform].resetFields();
    },
    search() {
      this.pageParams.current = 1;
      this.loadlist(this.pageParams);
    },
    /**
     * 编辑弹窗
     */
    updataDIC(row) {
      this.updatasonStatus = 1;
      this.sonform = JSON.parse(JSON.stringify(row));
      this.sondialogVisible = !this.sondialogVisible;
    },
    addDIC(id) {
      this.sonform.pid = id;
      this.updatasonStatus = 0;
      this.sondialogVisible = !this.sondialogVisible;
    },
    /**
     * 新增事件
     */
    submitsonfrom(sonform) {
      // this.sonform.pid = [this.sonform.pid]
      // this.sonform.dictName = this.sonform.name
      this.$refs[sonform].validate((valid) => {
        if (!valid) return false;
        dicsave(this.sonform).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.sondialogVisible = !this.sondialogVisible;
            this.loadlist();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    /**
     * 编辑提交事件
     */
    updatasubmitsonfrom(sonform) {
       this.sonform.pid = this.sonform.parentId
      this.$refs[sonform].validate((valid) => {
        if(!valid) return false 
        patrolEditDict(this.sonform).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.sondialogVisible = !this.sondialogVisible;
            this.loadlist();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    /**
     * 删除
     */
    del(row) {
      this.$confirm(`是否删除字典---[ ${row.name} ]?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        batchDelDict( [row.id] ).then((e) => {
          if (e.code === 200) {
            this.$message.success(e.msg);
            this.loadlist();
          } else {
            this.$message.error(e.msg);
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.contain{
  height: calc(100vh - 265px);
  padding: 10px 0;
}
</style>
